/***********************************
    FIX MODALS
************************************/
.modal {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    overflow: visible;
}

.modal-open .modal {
    overflow: visible;
}

.modal-top {
    margin-top: 0;
}

.modal .modal-header,
.modal .modal-header h1,
.modal .modal-header h2,
.modal .modal-header h3,
.modal .modal-header h4,
.modal .modal-header h5,
.modal .modal-header h6{
    padding: 0;
}

.modal-content{
    padding: 15px
}

/*
    Tables
*/

.table-unradius, .table-unradius tr, .table-unradius td {
    border-radius: unset;
}

.table-totals tfoot tr, tr.table-totals{
    background-color: #cccccc;
}

/***********************************
    START BTN BLACK
 ***********************************/
.btn-black {
    color: #ffffff;
    background-color: #262626;
    border-color: #262626;
}

.btn-black:hover,
.btn-black:focus,
.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
    color: #ffffff;
    background-color: #3D3D3D;
    border-color: #262626;
}

.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
    background-image: none;
}

.btn-black.disabled,
.btn-black[disabled],
fieldset[disabled] .btn-black,
.btn-black.disabled:hover,
.btn-black[disabled]:hover,
fieldset[disabled] .btn-black:hover,
.btn-black.disabled:focus,
.btn-black[disabled]:focus,
fieldset[disabled] .btn-black:focus,
.btn-black.disabled:active,
.btn-black[disabled]:active,
fieldset[disabled] .btn-black:active,
.btn-black.disabled.active,
.btn-black[disabled].active,
fieldset[disabled] .btn-black.active {
    background-color: #262626;
    border-color: #262626;
}

.btn-black .badge {
    color: #262626;
    background-color: #ffffff;
}

/***********************************
    END BTN BLACK
 ***********************************/