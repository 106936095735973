#main-menu li,
#main-menu li > a,
#main-menu li > i,
#main-menu li > a > i,
#main-menu li > span,
#main-menu li > a > span {
    font-size: 1.6rem;
}

.col-inline {
    height: 100%;
    float: none;
    display: inline-block;
}

.panel-subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 5px;
    padding-top: 0;
}

.swal2-popup {
    font-size: 1.4rem !important;
}

table.table tr td {
    vertical-align: top;
}

table.table-clickable-rows tbody tr {
    cursor: pointer;
}

tr.disabled {
    color: #909090;
}

/**
 * Fix time UP and time DOWN buttons
 */
.bootstrap-datetimepicker-widget .btn {
    background: none;
    border: none;
    border-radius: unset;
    color: #4c91be;
    box-shadow: none;
}

form .row {
    margin-bottom: 5px;
}

form legend {
    margin-bottom: 5px;
}

input:not([type]),
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
textarea.materialize-textarea {
    margin: 0 0 0;
}
/*
    Pesquisa personalizada do DataTables
 */
.filtros-avancados-container.filtros-hidden .filtros-avancados,
.filtros-avancados-container.filtros-hidden .when-filtros-visible,
.filtros-avancados-container.filtros-visible .when-filtros-hidden
{
    display: none;
}

.filtros-avancados-container.filtros-visible .filtros-avancados,
.filtros-avancados-container.filtros-visible .when-filtros-visible,
.filtros-avancados-container.filtros-hidden .when-filtros-hidden
{
    display: initial;
}

.filtros-avancados-container legend {
    border: none;
    font-size: 1.5rem;
}

.filtros-avancados input,
.filtros-avancados select,
.filtros-avancados textarea {
    padding-top: 0;
    padding-bottom: 0;
}

/*
    Showmore container
 */

.showmore-container .btn-showless {
    display: none;
}

.showmore-container .showmore-complete {
    display: none;
}

.showmore-container.complete .showmore-complete {
    display: initial;
}

.showmore-container.complete .showmore-truncated {
    display: none;
}

.showmore-container.complete .btn-showmore {
    display: none;
}

.showmore-container.complete .btn-showless {
    display: initial;
}


table.table-inverse tbody > tr > th {
    text-align: right
}